import { Box, Container, Flex, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { CustomSpinner } from "../../Spinner";
import RegionalTable from "./RegionalTable";
import useFetch from "../../../hooks/useFetchWithMsal";
import { useEffect } from "react";
import RegionalLeaderTable from "./RegionalLeaderTable";
import { useAccount,useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";


export default function RegionalViewContent({setDataSaved,ActiveRegionalID,save_enabled}) {
    const { accounts } = useMsal();
    const home_account_id = sessionStorage.getItem('home_account_id')
    const index = accounts.findIndex((val)=>val.homeAccountId===home_account_id)
    const account = useAccount(home_account_id?accounts[index] : null);
    const [{ data, loading, error}, fetchData] = useFetch();
    const navigate = useNavigate()

    useEffect(() => {
        if(!account){
            navigate("/")
        }else{
            if(ActiveRegionalID){
            fetchData(`/regionalView?userID=${ActiveRegionalID["ID"]}`, { method: "GET" }).then((data) => {
        })
        setDataSaved(true)

    }
        }
    }, [fetchData,account,navigate,ActiveRegionalID,setDataSaved])

    if(error) return (<p>Follwing Error has occurred {error}</p>)
    if (loading || !sessionStorage.getItem("aci")) return (
        <Container minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        < Box
            className="Loading-Data"
            maxW="md"
            mx="auto"
            p={6}
            borderRadius="xl"
            boxShadow="md"
            backgroundColor={"white"}
        >
            <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box >
        </Container>
    )

    return (
        <Flex flex="1" flexDirection="column">
            <Container maxW="container.xxl" py={8}>
                <Stack spacing={8}>
                    {(Object.keys(ActiveRegionalID).length) > 0 && <RegionalLeaderTable name={ActiveRegionalID["regional_leader_name"]} region={ActiveRegionalID["region"]} />}
                    <Tabs variant="enclosed" bg="white" boxShadow="md">
                        <TabList>
                            <Tab>Regional View</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {(Object.keys(data).length > 0) && (Object.keys(ActiveRegionalID).length) > 0 && (Object.keys(data.data).length > 0) && <RegionalTable setDataSaved={setDataSaved} data={data["data"]} saveEnabled={save_enabled} userProfile={ActiveRegionalID["ID"]} account_id={account?.idTokenClaims?.EmployeeID} />}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Stack>
            </Container>
        </Flex>
    );
}

