import {useEffect,useState } from 'react';
import  { 
    Tabs, TabList, TabPanels, Tab, TabPanel, 
    Box, Flex, Container, Stack
} from '@chakra-ui/react';
import { useMsal, useAccount, AuthenticatedTemplate } from '@azure/msal-react';
import SKUReport from './SKULevel/SKUReport';
import Header from './Header';
import OtherTableReport from './OtherLevel/OtherReport';
import Notification from './../Notifications/NotificationTable';
import useFetch from '../../../hooks/useFetchWithMsal';
import { CustomSpinner } from '../../Spinner';
import { useNavigate } from 'react-router-dom';
import RegionalLeaderTable from './RegionalLeaderTable';

export default function DashboardReport() {
    const { instance,accounts } = useMsal();
    const navigate=useNavigate()
    const home_account_id = sessionStorage.getItem('home_account_id')
    const index = accounts.findIndex((val)=>val.homeAccountId===home_account_id)
    const account = useAccount(home_account_id?accounts[index] : null);
    // const [userInfo,setUserInfo]=useState({})
    const [{ loading,error}, fetchData] = useFetch([]);
    const [isDataSaved,setIsDataSaved]=useState(true)
    const [activeRegion,setActiveRegion]=useState({})
    const [regionalIDList,setRegionalIDList]=useState([])

    useEffect(() => {
    if(!account){
        navigate("/")
    }
    else if(account.idTokenClaims.roles.includes("global_access")){
        if(sessionStorage.getItem("regionList")){  
           var regionalIDList=JSON.parse(sessionStorage.getItem("regionList"))
           setRegionalIDList(regionalIDList["result"])
           var aci = JSON.parse(sessionStorage.getItem("aci"))
           if(aci){
             setActiveRegion(aci)
           }
           else{
             setActiveRegion(regionalIDList["result"][0])
             sessionStorage.setItem("aci",JSON.stringify(regionalIDList["result"][0]))
           }
         }
         else{
           fetchData(`/regional_leader_id`, { method: "GET" }).then((data) => {
             setRegionalIDList(data["result"])
             sessionStorage.setItem("regionList",JSON.stringify(data))
             let aci = JSON.parse(sessionStorage.getItem("aci"))
             if(aci){
               setActiveRegion(aci)
             }
             else{
               setActiveRegion(data["result"][0])
               sessionStorage.setItem("aci",JSON.stringify(regionalIDList["result"][0]))
             }
           })
         } 
         setIsDataSaved(true)
       }
       else{
         let aci = JSON.parse(sessionStorage.getItem("aci"))
         if(aci){
           setActiveRegion(aci[0])
         }
         else{
           fetchData("/regional_leader", {method: "POST", body: {userID: account.idTokenClaims.EmployeeID}}).then((data)=>{
             setActiveRegion(data[0])
             sessionStorage.setItem("aci",JSON.stringify(data))
         })
         }
         setIsDataSaved(true)
       }
    // else if(account.idTokenClaims.roles.includes("global_access")){
    //     fetchData(`/regional_leader_id`, { method: "GET" }).then((data) => {
    //     setRegionalIDList(data["result"])
    //     if(activeRegionURL){
    //         var temp=data["result"].filter(value=>value["ID"]===parseInt(activeRegionURL))[0]
    //         setActiveRegion(temp)
    //       }
    //       else{
    //         setActiveRegion(data["result"][0])
    //       }
    //   })
    //     setIsDataSaved(true)
    //   }else{
    //     fetchData("/regional_leader", {method: "POST", body: {userID: account.idTokenClaims.EmployeeID}}).then((data)=>{
    //         setActiveRegion(data[0])
    //     });
    //     setIsDataSaved(true)
    //   }
    }, [account, fetchData,instance,navigate]);

    if(error) return (<p>Follwing Error has occurred {error}</p>)
    if (loading || !activeRegion) return (
        <Container minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        < Box
            className="Loading-Data"
            maxW="md"
            mx="auto"
            p={6}
            borderRadius="xl"
            boxShadow="md"
            backgroundColor={"white"}
        >
            <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box >
        </Container>
    )

    return (
        <AuthenticatedTemplate>
        <Box h="100vh">
        <Header dataSaved={isDataSaved} setActiveRegionalID={setActiveRegion} activeRegionalID={activeRegion} regionalIDList={regionalIDList}/>
            {/* Dashboard content */}
            <Flex flex="1" flexDirection="column">
            <Container maxW="container.xxl" py={8}>
                <Stack spacing={8}>
                    {Object.keys(activeRegion).length>0 && <RegionalLeaderTable  name={activeRegion["regional_leader_name"]} region={activeRegion["region"]} />}
                    {Object.keys(activeRegion).length>0 && <Tabs variant="enclosed" bg="white" boxShadow="md">
                        <TabList>
                            <Tab>SKU Forecast</Tab>
                            <Tab>Other Forecast</Tab>
                            <Tab>Notifications</Tab>
                        </TabList>
                        <TabPanels>
                        <TabPanel>
                        {Object.keys(activeRegion).length>0 && <SKUReport userProfile={activeRegion}/>}
                        </TabPanel>
                        <TabPanel>
                        {Object.keys(activeRegion).length>0 &&  <OtherTableReport userProfile={activeRegion}/>}
                        </TabPanel>
                        <TabPanel>
                            <Notification  userProfile={activeRegion} />
                        </TabPanel>
                        </TabPanels>
                    </Tabs>}
                </Stack>
                {loading &&
        <Box
        className="Loading-Data"
        maxW="md"
        mx="auto"
        p={6}
        borderRadius="xl"
        boxShadow="md"
        backgroundColor={"white"}
      >
        <CustomSpinner text="Loading Your Data.. Please Wait" />
      </Box>}
            </Container>
            </Flex>
        </Box>
       
        </AuthenticatedTemplate>
    );
}