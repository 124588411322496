import { UnauthenticatedTemplate,AuthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import {useEffect, React, useState} from 'react';
import { Box, Container, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CustomSpinner } from '../Spinner';
import {VERSION,PRODUCT_NAME} from "../../Constants"
import { Stack,Button,Text } from '@chakra-ui/react';
import { BsLock } from "react-icons/bs";
import { MSALCONFIG } from '../../Constants';
import { PublicClientApplication } from '@azure/msal-browser';



const Login = () => {
  const { instance,accounts } = useMsal();
  const [account,setAccount] = useState(null)
  const msalInstance = new PublicClientApplication(MSALCONFIG);
  const navigate = useNavigate();
  const Colour1 = useColorModeValue('bg-surface', 'gray.700')
  const [isLoading, setIsLoading] = useState(false);

  const versionTextStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "gray",
    padding: '10px',
  };
  


  const handleLoginRedirect = async () => {
    await instance.handleRedirectPromise().then((result) => {
      if (result && result.account && result.account.idTokenClaims && result.account.idTokenClaims.roles){
         if(result.account.idTokenClaims.roles.includes("Regional_Leader")||result.account.idTokenClaims.roles.includes("global_access")){
          setAccount(result.account)
          sessionStorage.setItem('home_account_id',result.account.homeAccountId)
          sessionStorage.setItem('accounts',accounts)
          navigate('/regionalView')
        }
        else{
          msalInstance.loginRedirect({
            prompt:"select_account",
            scopes:["user.read"]
          })
        }
      }
      else{
      // msalInstance.logoutRedirect()
      // msalInstance.logoutRedirect()
      setIsLoading(false)
      setAccount(null)
      }
    });
  };

  useEffect(() => 
  {
    if(account && account.idTokenClaims && account.idTokenClaims.roles) {
      if(account.idTokenClaims.roles.includes("Regional_Leader")||account.idTokenClaims.roles.includes("global_access")){
        sessionStorage.setItem('home_account_id',account.homeAccountId)
        navigate('/regionalView')
       }
       else{
         msalInstance.loginRedirect({
          prompt:"select_account",
          scopes:["user.read"]
        })
       }
    }
    // else{
    //   msalInstance.loginRedirect({
    //     prompt:"select_account",
    //     scopes:["user.read"]
    //   })
    // }
    handleLoginRedirect();
  })

  const handleLogin = async () => {
    try {
      await msalInstance.loginRedirect({
        prompt:"select_account",
        scopes:["user.read"]
      }
      );
      handleLoginRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  return (
    <Container 
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      >
      <UnauthenticatedTemplate>
      <Box
          className="login-form"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          bg={Colour1}
          boxShadow="md"
        >
          <Text fontSize="3xl" fontWeight="bold" textAlign="center" mb={6}>
            { PRODUCT_NAME }
          </Text>
          <Stack spacing={6}>
            <Button type="submit" onClick={handleLogin} colorScheme="teal" size="lg" leftIcon={<BsLock />}>
              Sign in With Microsoft
            </Button>
          </Stack>
          <Text style={versionTextStyle}>
            Version {VERSION}
          </Text>
        </Box>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
        {
          !account &&
          <Box
              className="login-form"
              maxW="md"
              mx="auto"
              p={6}
              borderRadius="xl"
              bg={Colour1}
              boxShadow="md"
            >
              <Text fontSize="3xl" fontWeight="bold" textAlign="center" mb={6}>
                { PRODUCT_NAME }
              </Text>
              <Stack spacing={6}>
                <Button type="submit" onClick={handleLogin} colorScheme="teal" size="lg" leftIcon={<BsLock />}>
                  Sign in With Microsoft
                </Button>
              </Stack>
              <Text style={versionTextStyle}>
                Version {VERSION}
              </Text>
            </Box>
          
        }</AuthenticatedTemplate>
        {isLoading &&
        <Box
        className="login-form"
        maxW="md"
        mx="auto"
        p={6}
        borderRadius="xl"
        bg={Colour1}
        boxShadow="md"
      >
        <CustomSpinner text="Login You In.. Please Wait" />
      </Box>
      }
      </Container>
      

  )
  
};

export default Login;
