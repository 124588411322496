import { Box } from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Header from './Header';
import { useMsal,useAccount } from '@azure/msal-react';
import RegionalViewContent from "./RegionalViewContent";
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetchWithMsal';
import { CustomSpinner } from '../../Spinner';
import { Container } from '@chakra-ui/react';


export default function RegionalView() {
  const [isDataSaved,setIsDataSaved]=useState(true)
  const {accounts } = useMsal();
  const navigate=useNavigate()
  const home_account_id = sessionStorage.getItem('home_account_id')
  const index = accounts.findIndex((val)=>val.homeAccountId===home_account_id)
  const account = useAccount(home_account_id?accounts[index] : null);
  const [activeRegion,setActiveRegion]=useState(null)
  const [{ loading, error}, fetchData] = useFetch();
  const [regionalIDList,setRegionalIDList]=useState([])
  const [save_enabled,setSave_enabled]=useState(false)
 

  useEffect(()=>{
    if(!account){
      navigate("/")
  }else if(
    
    account.idTokenClaims.roles.includes("global_access")){
   if(sessionStorage.getItem("regionList")){  
      var regionalIDList=JSON.parse(sessionStorage.getItem("regionList"))
      setRegionalIDList(regionalIDList["result"])
      setSave_enabled(regionalIDList["save_enabled"])
      let aci = JSON.parse(sessionStorage.getItem("aci"))
      if(aci){
        setActiveRegion(aci)
      }
      else{
        setActiveRegion(regionalIDList["result"][0])
        sessionStorage.setItem("aci",JSON.stringify(regionalIDList["result"][0]))
      }
    }
    else{
      fetchData(`/regional_leader_id`, { method: "GET" }).then((data) => {
        console.log(data)
        setRegionalIDList(data["result"])
        sessionStorage.setItem("regionList",JSON.stringify(data))
        setSave_enabled(data["save_enabled"])
        let aci = JSON.parse(sessionStorage.getItem("aci"))
        if(aci){
          setActiveRegion(aci)
        }
        else{
          setActiveRegion(data["result"][0])
          sessionStorage.setItem("aci",JSON.stringify(data["result"][0]))
        }
      })
    } 
    setIsDataSaved(true)
  }
  else{
    let aci = JSON.parse(sessionStorage.getItem("aci"))
    if(aci){
      setActiveRegion(aci[0])
      setSave_enabled(aci[0]["save_enabled"])
    }
    else{
      fetchData("/regional_leader", {method: "POST", body: {userID: account.idTokenClaims.EmployeeID}}).then((data)=>{
        setActiveRegion(data[0])
        setSave_enabled(data[0]["save_enabled"])
        sessionStorage.setItem("aci",JSON.stringify(data))
    })
    }
    setIsDataSaved(true)
  }
  },[account,navigate,setIsDataSaved,fetchData])

  if(error) return (<p>Follwing Error has occurred {error}</p>)
  if (loading || !activeRegion) return (
      <Container minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      < Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
      >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
      </Box >
      </Container>
  )
  return (
    <AuthenticatedTemplate>
    <Box minHeight="100vh">
     <Header dataSaved={isDataSaved} setActiveRegionalID={setActiveRegion} activeRegionalID={activeRegion} regionalIDList={regionalIDList}/>
     <RegionalViewContent setDataSaved={setIsDataSaved} ActiveRegionalID={activeRegion} save_enabled={save_enabled}/>
    </Box>
    </AuthenticatedTemplate>
  );

}
